import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'

// Header Images
import intro_header from '../images/header_pictures/intro.png'
import how_to_header from '../images/header_pictures/how_to.png'
import about from '../images/header_pictures/about.png'
import gallery from '../images/header_pictures/gallery.png'

// How It Works Images

import img001 from '../images/how_it_works/img001.jpg'
import img002 from '../images/how_it_works/img002.jpg'
import img003 from '../images/how_it_works/img003.jpg'
import img004 from '../images/how_it_works/img004.jpg'
import img005 from '../images/how_it_works/img005.jpg'
import img006 from '../images/how_it_works/img006.jpg'
import img007 from '../images/how_it_works/img007.jpg'
import img008 from '../images/how_it_works/img008.jpg'
import img009 from '../images/how_it_works/img009.jpg'
import img010 from '../images/how_it_works/img010.jpg'

// Gallery
// Without Leg
import no_leg001 from '../images/gallery/without_leg/no_leg001.jpg'
import no_leg002 from '../images/gallery/without_leg/no_leg002.jpg'
import no_leg003 from '../images/gallery/without_leg/no_leg003.jpg'
import no_leg004 from '../images/gallery/without_leg/no_leg004.jpg'
import no_leg005 from '../images/gallery/without_leg/no_leg005.jpg'
import no_leg006 from '../images/gallery/without_leg/no_leg006.jpg'

// With Leg
import leg001 from '../images/gallery/with_leg/leg001.jpg'
import leg002 from '../images/gallery/with_leg/leg002.jpg'
import leg003 from '../images/gallery/with_leg/leg003.jpg'
import leg004 from '../images/gallery/with_leg/leg004.jpg'
import leg005 from '../images/gallery/with_leg/leg005.jpg'
import leg006 from '../images/gallery/with_leg/leg006.jpg'
import leg007 from '../images/gallery/with_leg/leg007.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Intro</h2>
          <span className="image main">
            <img src={intro_header} alt="" />
          </span>
          <p>
            Honest to our word, we do not make tools just to make them. Like a
            level or a trowel, the PLANUM - Tile Niche Supporter is a tool every tile
            installer needs to have. No more second guessing your work, our tool
            is designed to help finish the job with no frustration and 100%
            satisfaction guaranteed.
          </p>
          <h3>Features:</h3>
          <ul>
            <h4>Hard Aluminium – light, durable, and doesn’t rust</h4>
            <li>
              The tile niche supporter is made from aluminum and has a powerful
              paint powder coating for a better grip. It doesn’t rust and fits
              perfectly in any size tool box. It is lightweight but mighty
              strong!
            </li>
            <br />
            <h4>Quick and easy set up.</h4>
            <li>
              Using the tool is very easy. It takes little to no effort to
              understand how the tool functions, and once you are familiar, it
              will take less than a minute to install it. No more using
              improvised materials! Removing the tool is just as easy as
              installing it, remember to let the mortar dry first.
            </li>
            <br />
            <h4>Comes with Extended Leg & Patented Technology</h4>
            <li>
              Patent pending: this tool is unique, and you cannot find it
              anywhere else. Our unique design was made to improve productivity
              and eliminate the frustration of searching for and/or using
              improvised materials.
            </li>
          </ul>
          <h3>Benefits:</h3>
          <ul>
            <h4>
              Saves time through efficiency – very fast set up (under a minute)
            </h4>
            <li>
              The PLANUM - Tile Niche Supporter increases work flow. The tool is made for
              professional and novice tile installers who will save time by
              eliminating the search for improvised materials. Our support is
              100% relatable. Pre-plan your tiling layout, install, let dry, and
              remove. Adjustable plate makes working with any dimensions easy
              and painless.
            </li>
            <br />
            <h4>Travels well – very light, fits well in a tool box</h4>
            <li>
              Our very reliable support only weighs 2 lbs and fits perfectly in
              any tool box or bag! The detachable extended leg lays flat against
              the main plate when not in use.
            </li>
            <br />
            <h4>Pairs of Tile Niche Supporters work well together</h4>
            <li>
              In some situations with longer niches or longer suspended walls
              one tile supporter is not enough to hold all the tiles above the
              niche, window or suspended wall. You can use as many supporters as
              necessary to get the job done.
            </li>
          </ul>

          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">How It Works</h2>
          <span className="image main">
            <img src={how_to_header} alt="" />
          </span>
          <p>
            The Tile Niche Supporter is ready for your tiling and supporting
            needs featuring options to attach to different surfaces with screws,
            or with the enclosed extended leg. The tool is ready to use
            immediately, set-up takes less than one minute, and it is so
            relatable that you can leave tiles alone to dry. It saves tilers
            time and eliminates the frustration of looking for improvising
            materials to support tiles. Our team has a 100% success rate of
            completing the niche on the same day.
          </p>
          <p>
            The tool can be used for bathroom or window niches. For larger
            projects, we recommend using multiple Tile Niche Supporters for
            durable strength and support.{' '}
          </p>
          <p>
            The following are examples of ways tilers have used our product.
          </p>
          <div className="row">
            <div className="column">
              <img src={img001} alt="" />
            </div>
            <div className="column">
              <img src={img002} alt="" />
            </div>
          </div>
          <p>
            For niches like this, a tiler used 3 Tile Niche Supporters(with
            screws) to hold everything together, evenly placed to distribute the
            weight.
          </p>
          <div className="column">
            <img src={img003} alt="" />
          </div>
          <p>
            In this case, a tiler used 2 Tile Niche Supporters screwed into the
            door frame for extra weight support.
          </p>
          <div className="row">
            <div className="column">
              <img src={img006} alt="" />
            </div>
            <div className="column">
              <img src={img007} alt="" />
            </div>
          </div>
          <p>Only one Niche supporter, used with the extended leg.</p>
          <div className="column">
            <img src={img008} alt="" />
          </div>
          <p>Two Niche Supporter with extended leg</p>
          <h3>Instructions</h3>
          <p>You can use the Tile Niche Supporter in two ways:</p>
          <ul>
            <h4>Tile Niche Supporter With Screws (not included):</h4>
            <p>
              Use when the frame of the walls are made of wood or any other
              drillable material.
            </p>
            <li>
              Place the main support flushed against the desired surface with
              the lower support plate open. Fasten at least two screws on each
              side of the threaded rod. More screws might be necessary depending
              on the weight of tiles. We suggest a weight limit up to 200 lb
            </li>
            <li>
              Adjust the lower plate to desired placement by twisting the
              plastic nut.
            </li>
            <li>
              Install tiles and wait until completely dry before removing.
            </li>
          </ul>
          <div className="column">
            <img src={img009} alt="" />
          </div>
          <ul>
            <br />
            <h4>Tile Niche Supporter With Extended Leg:</h4>
            <p>
              When the frame of the walls consists of a non-drillable material
              such as concrete walls, foam, or plastic, or to avoid drilling
              into the walls.
            </p>
            <li>Screw extended leg on the Tile Niche Supporter.</li>
            <li>
              Place the main and lower support flushed against the desired
              surface.The bottom of the leg has to be fixed on a very strong and
              non-moving surface.
            </li>
            <li>
              Adjust leg length to desire length with plastic nut attached to
              the leg. Install tiles and wait until completely dry before
              removing
            </li>
          </ul>
          <div className="column">
            <img src={img010} alt="" />
          </div>
          <ul></ul>
          <ul></ul>

          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          <span className="image main">
            <img src={about} alt="" />
          </span>
          <p>
            We are Planum, an authentic family-owned business specializing in
            tilling. With 20+ years of tling experience we know how it feels to
            use makeshift boxes, wood, or anything you can grab to create a
            support for tile installation over vertical surfaces. Today, we are
            proud to bring you the first patent tile support for all your tiling
            needs.
          </p>
          <p>
            A few years ago, our founder was hired for a tile installation in
            downtown Chicago. Among others, he was tasked to install tiles over
            a big niche. He started tiling and at the end he needed to improvise
            materials to create a support for the tiles installed on top of the
            niche. With no luck in finding the right materials, he had to leave
            the building, remove his car from a complicated garage, and go out
            of his way to the nearby hardware store to find anything that can
            support the tiles. He had to buy materials to create a makeshift
            support. Several hours later, he finished the job, but felt
            frustrated with wasting productivity hours and time. That same day,
            he drew up the first prototype of the Title Niche Supporter. Several
            years later, we are here to present the tool that saves time and
            eliminates frustration - the Tile Niche Supporter.
          </p>
          <p>
            Planum users can rest assured; we spent years rendering the absolute
            best tile niche supporter to ensure every tile stays in place!
          </p>
          {close}
        </article>

        <article
          id="gallery"
          className={`${this.props.article === 'gallery' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Gallery</h2>
          <span className="image main">
            <img src={gallery} alt="" />
          </span>
          <h4>PLANUM - tile niche supporter used with screws</h4>
          <iframe
            width="100%"
            height="400"
            src="https://www.youtube.com/embed/er55XQKrUe0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <div className="row">
            <div className="column">
              <img src={no_leg001} alt="" />
            </div>
            <div className="column">
              <img src={no_leg002} alt="" />
            </div>
          </div>
          <div className="row">
            <div className="column">
              <img src={no_leg003} alt="" />
            </div>
            <div className="column">
              <img src={no_leg004} alt="" />
            </div>
          </div>
          <div className="column">
            <img src={no_leg006} alt="" />
          </div>
          <div className="column">
            <img src={no_leg005} alt="" />
          </div>
          <br />
          <h4>PLANUM - tile niche supporter used with extended leg</h4>
          <iframe
            width="100%"
            height="400"
            src="https://www.youtube.com/embed/6QhujI3PuHk"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <div className="row">
            <div className="column">
              <img src={leg001} alt="" />
            </div>
            <div className="column">
              <img src={leg002} alt="" />
            </div>
          </div>
          <div className="row">
            <div className="column">
              <img src={leg003} alt="" />
            </div>
            <div className="column">
              <img src={leg004} alt="" />
            </div>
          </div>
          <div className="row">
            <div className="column">
              <img src={leg005} alt="" />
            </div>
            <div className="column">
              <img src={leg006} alt="" />
            </div>
          </div>

          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form
            name="contact"
            method="POST"
            netlify
            // action="http://www.tilesupporter.com"
            data-netlify="true"
          >
            <input type="hidden" name="form-name" value="contact" />
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <h4>Address: 5408 N Kedzie Ave. Chicago, IL 60625</h4>
          <h4>Phone: (773) 988-7240 </h4>
          <h4>e-mail: info@tilesupporter.com</h4>
          {/* <ul className="icons">
            <li>
              <a
                href="https://twitter.com/"
                className="icon fa-youtube"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
          </ul> */}
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
